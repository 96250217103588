<template>
  <div class="apitest">
    {{ products }}
    <br />名字：
    <input v-model="name" />
    <br />价格：
    <input v-model="price" />
    <br />
    <button @click="postProducts">加入</button>
    <button @click="delProduct">删除</button>
    <br />
    {{ res }}
  </div>
</template>

<script>
export default {
  name: 'Apitest',
  data() {
    return {
      products: [],
      name: '',
      price: '',
      res: '',
    };
  },
  mounted() {
    this.reflashProducts();
  },
  methods: {
    reflashProducts() {
      this.axios.get('/api/products').then(res => (this.products = res.data));
    },
    postProducts() {
      this.axios.post('/api/products', {
        name: this.name,
        price: this.price,
      });
      this.reflashProducts();
    },
    delProduct() {
      this.axios
        .delete('/api/products')
        .then(res => (this.res = res))
        .catch(err => {
          console.log(err);
          this.res = err.response;
        });
    },
  },
};
</script>
